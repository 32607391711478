import React, {useEffect, useState, FC, useRef} from 'react';
import ReactTooltip from 'react-tooltip';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {Link, navigate} from 'gatsby';
//@ts-ignore
import {useMediaQuery} from 'react-responsive';
import classNames from 'classnames';
import {Location} from '@reach/router';
import {faSignOutAlt, faChartLine} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ADD_REFERRAL, LOG_OUT} from '../../graphql/mutations';
import logo from '../../images/hexomatic-logo.svg';

import backgroun1 from '../../images/black-friday-deal/top-bar/background-1.svg';
import backgroun2 from '../../images/black-friday-deal/top-bar/background-2.svg';
import promoText from '../../images/black-friday-deal/top-bar/promo-text-1.svg';
import mobileBanner from '../../images/black-friday-deal/top-bar/banner-mobile.svg';

import background1LTD from '../../images/limited-time-ltd/background-1.svg';
import background2LTD from '../../images/limited-time-ltd/background-2.svg';
import promoTextLTD from '../../images/limited-time-ltd/promo-text.svg';
import mobileBannerLTD from '../../images/limited-time-ltd/mobile-banner.svg';

import background1Annual from '../../images/annual-discount/background-1.svg';
import background2Annual from '../../images/annual-discount/background-2.svg';
import promoTextAnnual from '../../images/annual-discount/promo-text.svg';
import mobileBannerAnnual from '../../images/annual-discount/mobile-banner.svg';

// import popupMobile from '../../images/start-engine/popup-mobile.svg';
// import popupDesktop from '../../images/start-engine/popup-desktop.svg';

import {
  getUserEmail,
  getUserName,
  logOut,
  sendUserDataToExtension,
  getUserToken,
  setHotJarUser,
  getRawCookie,
  getPromoDate,
} from '../../helpers';
import {HeaderState, MenuItem, HeaderProps} from '../../types';

import HeaderMenuItem from './item';
import HeaderMenuSubItem from './sub-item';
// import Counter from './counter';

import './styles.scss';
import {GET_FLASH_PROMO_DATA, GET_HEXOMATIC_USER, HEXOFY_USER, LEFT_PACKAGES_COUNT, USER_PLANS_QUERY} from '../../graphql/queries';
import Counter from '../countdown';
import {closeIcon} from '../../images/svgIcons';
import {Modal} from '../../components/modal';
import { openCalendly } from '../../helpers/trackingEvents';
// import Counter from './counter';
// import {HexomaticUserContext} from '../../helpers/contexts/hexomatic-user';
// import Counter from './counter';
// import Counter from './counter';

const generateMenu = (items: Array<MenuItem>, pathName: string, gravatarSrc?: string): Array<JSX.Element> => {
  return items.map(
    (item: MenuItem): JSX.Element => {
      return (
        <li
          key={item.name}
          className={classNames('nav-item dropdown submenu', {
            active: item.linkTo === pathName || item.showSubItems,
            dropdownMenu: item.subMenu,
          })}
        >
          <HeaderMenuItem
            to={item.linkTo}
            name={item.name}
            target={item.target}
            icon={item.icon}
            img={item.img}
            src={gravatarSrc}
            className={item.className}
            func={item.func}
            showSubItems={item.showSubItems}
          />
          {item.subItems && (
            <ul
              className={classNames('dropdown-menu avatar', {
                show: item.showSubItems,
              })}
            >
              {item.subItems.map(
                (subItem: MenuItem): JSX.Element => (
                  <HeaderMenuSubItem
                    key={subItem.name}
                    to={subItem.linkTo}
                    name={subItem.name}
                    target={subItem.target}
                    icon={subItem.icon}
                    className={subItem.className}
                    func={subItem.func}
                  />
                ),
              )}
            </ul>
          )}

          {item.subMenu && (
            <ul
              className={classNames('subMenu', {
                show: item.showSubItems,
              })}
            >
              {item.subMenu.map(
                (subItem: MenuItem): JSX.Element => (
                  <HeaderMenuSubItem
                    key={subItem.name}
                    to={subItem.linkTo}
                    name={subItem.name}
                    target={subItem.target}
                    icon={subItem.icon}
                    className={subItem.className}
                    func={subItem.func}
                  />
                ),
              )}
            </ul>
          )}
        </li>
      );
    },
  );
};

const Header: FC<HeaderProps> = ({menuItems, user, loading, promoOpen, holidaysSale, setPromoOpen, pricingPage, pricingPageLtd, fusebasePromo, shorthLtd, noBanner }) => {
  const [state, changeState] = useState<HeaderState>({
    isMenuCollapsed: true,
    navBarFixClassName: 'header_area',
    isButtonClicked: false,
    isDropDownOpened: false,
  });

  const [email, setemail] = useState('email');
  const refUserName = useRef<HTMLParagraphElement | null>(null);
  const refName = useRef<HTMLParagraphElement | null>(null);
  const [checkWidth, setCheckWidth] = useState({firstName: false, firstName1: false});
  const [isGravatarOpen, setIsGravatarOpen] = useState(false);
  const [addRefferal] = useMutation(ADD_REFERRAL);
  const [showAnnualDiscount, setShowAnnualDiscount] = useState(false);
  // const [leftSeats, setLeftSeats] = useState(0 as any);
  // const [showInvestPopup, setShowInverstPopup] = useState(false);
  // const [flashPromoDate, setFlashPromoDate] = useState(null as any);
  // const [showFlashPromo, setShowFlashPromo] = useState(false);

  const [hexomaticUser, setHexomaticUser] = useState<any>(null);

  const {data: hexomaticUserInfo} = useQuery(GET_HEXOMATIC_USER, {
    fetchPolicy: 'no-cache',
  });

  const { data: allPlansData, loading: allPlansLoading } = useQuery(USER_PLANS_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const { data: hexofyUserData } = useQuery(HEXOFY_USER, {
    fetchPolicy: 'no-cache',
  });

  // const {data: leftSeatsData} = useQuery(LEFT_PACKAGES_COUNT);
  // const {data: flashPromo, loading: flashPromoLoading} = useQuery(GET_FLASH_PROMO_DATA, {
  //   fetchPolicy: 'no-cache',
  // });

  const [log_out] = useMutation(LOG_OUT);

  // useEffect(() => {
  //   setTimeout(() => {
  //     const show = localStorage.getItem('become-investor-2');
  //     !show ? setShowInverstPopup(true) : setShowInverstPopup(false);
  //   }, 5000);
  // }, []);

  //OPEN PROMO AFTER LTD PROMO ENDS

  // useEffect(() => {
  //   // const packageHexomatic =
  //   //   hexomaticUserInfo &&
  //   //   hexomaticUserInfo.HexomaticUser &&
  //   //   hexomaticUserInfo.HexomaticUser.getByLegacyId &&
  //   //   hexomaticUserInfo.HexomaticUser.getByLegacyId.pricing_package;

  //   // const flashPromoDate =
  //   //   flashPromo &&
  //   //   flashPromo.User &&
  //   //   flashPromo.User.getHcFlashPromoCronDate &&
  //   //   flashPromo.User.getHcFlashPromoCronDate.end_date;

  //   if (hexomaticUserInfo && hexomaticUserInfo.HexomaticUser && hexomaticUserInfo.HexomaticUser) {
  //     setHexomaticUser(hexomaticUserInfo.HexomaticUser.getByLegacyId);
  //     const plan = hexomaticUserInfo.HexomaticUser.getByLegacyId.pricing_package;
  //     setShowAnnualDiscount(plan && plan !== 'FREE' ? false : true);
  //   }
  //   // if (
  //   //   hexomaticUserInfo &&
  //   //   packageHexomatic === 'FREE' &&
  //   //   flashPromoDate &&
  //   //   parseInt(flashPromoDate) > new Date().getTime()
  //   // ) {
  //   //   setShowFlashPromo(true);
  //   //   setFlashPromoDate(flashPromoDate);
  //   // }
  // }, [hexomaticUserInfo]);

  useEffect(() => {
    if (allPlansData?.User?.getUserPlans) {
      const userPlans = allPlansData?.User?.getUserPlans;
      const hexomaticPlanName = userPlans.hexomatic?.pricing_package;
      // const hexowatchPlanName = userPlans.hexowatch?.pricing_package;
      // const hexometerPlanName = userPlans.hexometer?.pricing_package;
      // const hexosparkPlanName = userPlans.hexospark?.pricing_package;
      // const hexomaticPlanInterval = userPlans.hexomatic?.pricing_package_interval;
      // const hexowatchPlanInterval = userPlans.hexowatch?.pricing_package_interval;
      // const hexometerPlanInterval = userPlans.hexometer?.pricing_package_interval;
      // const hexosparkPlanInterval = userPlans.hexospark?.pricing_package_interval;

      // const hexofyPlanInterval = hexofyUserData?.HexofyUser?.getHexofyUser?.hfUser?.pricing_package_interval;
      // const hexofyPlanName = hexofyUserData?.HexofyUser?.getHexofyUser?.hfUser?.pricing_package;

      const hexomaticFree = hexomaticPlanName === 'FREE' || (userPlans && !hexomaticPlanName);
      // const hexowatchFree = hexowatchPlanName === 'FREE' || (userPlans && !hexowatchPlanName);
      // const hexometerFree = hexometerPlanName === 'FREE' || (userPlans && !hexometerPlanName);
      // const hexosparkFree = hexosparkPlanName === 'FREE' || (userPlans && !hexosparkPlanName);
      // const hexofyFree = hexofyPlanName === 'FREE' || (userPlans && !hexofyPlanName);
      // const showModal =
      //   (hexomaticFree || hexomaticPlanInterval === 'LTD') &&
      //   (hexowatchFree || hexowatchPlanInterval === 'LTD') &&
      //   (hexometerFree || hexometerPlanInterval === 'LTD') &&
      //   (hexofyFree || hexofyPlanInterval === 'LTD') &&
      //   (hexosparkFree || hexosparkPlanInterval === 'LTD');
      if (hexomaticFree && !noBanner) {
        setShowAnnualDiscount(false);
      } else {
        setShowAnnualDiscount(false);
      }
    }
  }, [user, hexofyUserData, allPlansData, noBanner]);

  const handleScrollEvent = () => {
    const top: number = document.documentElement.scrollTop || document.body.scrollTop;
    //116 instead of 1 for promos
    if (top > 116 && state.navBarFixClassName !== 'header_area navbar_fixed') {
      changeState({
        ...state,
        navBarFixClassName: 'header_area navbar_fixed',
      });
    } else if (top <= 116 && state.navBarFixClassName === 'header_area navbar_fixed') {
      changeState({
        ...state,
        navBarFixClassName: 'header_area',
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [state.navBarFixClassName]);

  // useEffect(() => {
  //   if (
  //     leftSeatsData?.User?.getHexomateLeftLTDPackagesCount?.count &&
  //     leftSeatsData.User.getHexomateLeftLTDPackagesCount.count > 0
  //   ) {
  //     setLeftSeats(leftSeatsData.User.getHexomateLeftLTDPackagesCount.count);
  //   }
  // }, [leftSeatsData]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let referral = urlParams.get('referralCode');
    if (referral) {
      if (referral[referral.length - 1] === '/') {
        referral = referral.slice(0, -1);
      }
      const d = new Date();
      d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
      window.document.cookie = `referralCode=${referral};expires=${d.toUTCString()};path=/`;
      // ADD_REFERRAL
      addRefferal({variables: {referralCode: referral}});
    }
  }, []);

  const logOutHandler = async (): Promise<void> => {
    const {data} = await log_out({variables: {id: user && +user.id, device_key: getRawCookie('device_key')}});
    if (data && data.UserOps && data.UserOps.logout && !data.UserOps.logout.error) {
      logOut();
      setemail(getUserEmail() || '');
      sendUserDataToExtension();
      // setShowAnnualDiscount(true);
    }
  };

  // const logOutHandler = async (): Promise<void> => {
  //   logOut();
  //   setemail(getUserEmail() || '');
  //   sendUserDataToExtension();
  // };

  const toggle = () => {
    setIsGravatarOpen(!isGravatarOpen);
  };

  // const [color, dispatch] = useContext(ColorContext);

  const toggleHandler = (): void =>
    changeState({
      ...state,
      isMenuCollapsed: !state.isMenuCollapsed,
      isDropDownOpened: !state.isDropDownOpened,
    });

  // const handlePromoClose = () => {
  //   const el = document.getElementById('promo-header');
  //   //@ts-ignore
  //   el && el.animate({opacity: '0'}, 150);
  //   setTimeout(() => {
  //     setPromoOpen(false);
  //     setLTDHeader('false');
  //   }, 150);
  // };

  // useEffect(() => {
  //   const el1 = document.getElementById('seo_home_area');
  //   const el2 = document.getElementById('seo_home_area_automations');
  //   if (showFlashPromo) {
  //     if (el1 && el1.classList && el1.classList.contains('no-padding')) {
  //       el1 && el1.classList.add('promo-padding-top');
  //     } else {
  //       el1 && el1.classList.add('promo-padding');
  //     }
  //     el2 && el2.classList.add('promo-padding-automations');
  //   } else {
  //     el1 && el1.classList.remove('promo-padding');
  //     el2 && el2.classList.remove('promo-padding-automations');
  //   }
  // }, [showFlashPromo]);

  useEffect(() => {
    const el1 = document.getElementById('seo_home_area');
    const el2 = document.getElementById('seo_home_area_automations');
    const el3 = document.getElementById('seo_home_area_tools');
    const el4 = document.getElementById('seo_home_area_promo');
    if (showAnnualDiscount) {
      if (el1 && el1.classList && el1.classList.contains('no-padding')) {
        el1 && el1.classList.add('promo-padding-top');
        el3 && el3.classList.add('promo-padding-top');
      } else {
        el1 && el1.classList.add('promo-padding');
        el3 && el3.classList.add('promo-padding');
      }
      el2 && el2.classList.add('promo-padding-automations');
      el4 && el4.classList.add('promo-padding-promo-page');
    } else {
      el1 && el1.classList.remove('promo-padding');
      el2 && el2.classList.remove('promo-padding-automations');
      el3 && el3.classList.remove('promo-padding');
      el4 && el4.classList.remove('promo-padding-promo-page');
    }
  }, [promoOpen, pricingPageLtd, user, showAnnualDiscount, loading]);

  // const isMobile = useMediaQuery({maxWidth: 991});

  useEffect(() => {
    if (user && user.email) {
      const cookie = getUserToken();
      const refToken = getRawCookie('ref_token');
      const deviceKey = getRawCookie('device_key');
      sendUserDataToExtension(cookie, refToken, deviceKey);
      if (hexomaticUser && hexomaticUser.id) {
        setHotJarUser(
          +user.id,
          hexomaticUser.pricing_package === 'FREE' ? 'free' : 'paid',
          hexomaticUser.pricing_package,
          hexomaticUser.pricing_package_interval,
          user.premium_credits_pricing_package,
        );
      }
    }
  }, [user, hexomaticUser]);

  useEffect(() => {
    if (user?.firstName && refUserName.current && refName.current) {
      setCheckWidth({
        firstName: refUserName?.current?.scrollWidth !== refUserName?.current?.offsetWidth,
        firstName1: refName?.current?.scrollWidth !== refName?.current?.offsetWidth,
      });
    }
  }, [user, refUserName.current, refName.current]);

  return (
    <header className={`white_bg ${state.navBarFixClassName}`} data-testid="header">
      {/* <div
        className="w-100 position-relative d-none d-lg-flex"
        style={{background: '#16AC59', cursor: 'pointer'}}
        onClick={() => window.open('https://www.startengine.com/offering/hexact/')}
      >
        <img src={backgroun1} alt="" className="illustration1 d-none d-lg-flex" />
        <div className="w-100 d-flex">
          <img
            src={promoText}
            alt="Become an investor in Hexact"
            className="my-auto mx-xl-auto promo-text"
            style={{zIndex: 100}}
          />
        </div>
        <img src={backgroun2} alt="" className="illustration2" />
      </div>
      <div
        className="w-100 position-relative d-flex d-lg-none"
        style={{background: '#00C869', cursor: 'pointer'}}
        onClick={() => window.open('https://www.startengine.com/offering/hexact/')}
      >
        <img src={mobileBanner} alt="Become an investor in Hexact" className="m-auto" width={230} height={116} style={{maxWidth: '230px', width: '100%', height: 'auto'}} />
      </div> */}
      {/* {!pricingPageLtd && (
        <div
          className={`text-center d-flex flex-wrap justify-content-center annual-promo-wrapper cursor-pointer`}
          onClick={() => navigate('/EVERYTHING-BF-2023/')}
        >
          <div className="justify-content-center d-none d-lg-flex" style={{width: '100%'}}>
            <img src={backgroun1} alt="illustration1" className="illustration1" />
            <div
              className="d-flex justify-content-center w-100 illustrations-container"
              style={{maxWidth: '1100px', position: 'relative', height: '60px'}}
            >
              <img src={promoText} alt="promoText" className="py-0 px-2 my-auto" style={{maxWidth: '100%', maxHeight: '100%'}} />
              <div className="my-auto">
                <Counter date="Dec 01 2023 07:00:00 GMT" title="DEAL ENDS IN..." light />
              </div>
            </div>
            <img src={backgroun2} alt="illustration2" className="illustration2 d-none d-xl-block" />
          </div>
          <div className="d-block d-lg-none justify-content-center" style={{width: '100%'}}>
            <img
              src={mobileBanner}
              alt="ltd"
              style={{maxHeight: '150px', height: 'auto', maxWidth: '100%'}}
              width={290}
              height={150}
            />
            <div className="m-auto countdown-absolute">
              <Counter date="Dec 01 2023 07:00:00 GMT" title="DEAL ENDS IN..." light />
            </div>
          </div>
        </div>
      )} */}
      {(pricingPageLtd || showAnnualDiscount) && !noBanner && (
        <div
          className={`text-center d-flex flex-wrap justify-content-center ltd-wrapper cursor-pointer ${pricingPageLtd ? '' : 'annual-promo-wrapper'}`}
          onClick={() => pricingPageLtd ? navigate(shorthLtd ? '/promo30421/#deal' : '/promo23059/#deal') : navigate('/pricing/')}
        >
          <div className="justify-content-center d-none d-lg-flex" style={{width: '100%'}}>
            <img src={pricingPageLtd ? background1LTD : background1Annual} alt="illustration1" className="illustration1" />
            <div
              className="d-flex justify-content-center w-100 m-auto"
              style={{maxWidth: '1100px', margin: 'auto', position: 'relative', height: '60px', zIndex: 1}}
            >
              <img src={pricingPageLtd ? promoTextLTD : promoTextAnnual} alt="promoText" className="py-0 px-2 my-auto" style={{maxHeight: pricingPageLtd ? '' : '60px', maxWidth: '100%'}} />
              <div className="my-auto">
                <Counter date={shorthLtd ? 'Aug 23 2024 07:00:00 GMT' : !pricingPageLtd ?  getPromoDate() : getPromoDate()} title="DEAL ENDS IN..." light={pricingPageLtd} />
              </div>
            </div>
            <img src={pricingPageLtd ? background2LTD : background2Annual } alt="illustration2" className="illustration2 d-none d-xl-block" />
          </div>
          <div className="d-block d-lg-none justify-content-center" style={{width: '100%'}}>
            <img
              src={pricingPageLtd ? mobileBannerLTD : mobileBannerAnnual }
              alt="ltd"
              style={{maxHeight: '200px', height: 'auto', maxWidth: '100%'}}
              width={290}
              height={200}
            />
            <div className="m-auto countdown-absolute">
              <Counter date={shorthLtd ? 'Aug 23 2024 07:00:00 GMT' : !pricingPageLtd ?  getPromoDate() : getPromoDate()} title="DEAL ENDS IN..." light={pricingPageLtd} />
            </div>
          </div>
        </div>
      )}
      {/* {holidaysSale && !promoOpen && (
        <div
          className={`text-center d-flex flex-wrap justify-content-center holidays-sale cursor-pointer`}
          id="promo-header"
          onClick={() => window.open('https://dash.hexomatic.com/dashboard#modal_open', '_blank')}
        >
          <div className="d-flex justify-content-center" style={{width: '100%'}}>
            <img src={background1} alt='illustration1' className='illustration1 d-none d-xl-block' />
            <div className="d-lg-flex d-lg-flex-wrap justify-content-center" >
              <img alt ="holidaySale" src={isMobile ? holidaySaleMobile : holidaySaleMobile} style={{maxWidth: '100%', height: 'auto', width: 'auto', maxHeight: '68px', padding: '10px 15px', cursor: 'pointer'}} />
              <div className='m-auto'>
                <Counter />
              </div>
            </div>
              <img src={background2} alt='illustration2' className='illustration2 d-none d-xl-block' />
            </div>
        </div>
      )} */}

      {/* <CookiePolicy
        title="COOKIE_POLICY_TITLE"
        subTitle="COOKIE_POLICY_SUBTITLE"
        actionText="COOKIE_POLICY_BUTTON_TEXT"
      /> */}
      <nav className="navbar navbar-expand-xl menu_one menu_five">
        <div className="container">
          <Link to="/" className="navbar-brand sticky_logo">
            <img src={logo} alt="Hexomatic.com" width={210} height={53} />
          </Link>
          <button
            className={classNames('navbar-toggler', {
              collapsed: state.isMenuCollapsed,
            })}
            onClick={toggleHandler}
            type="button"
            data-testid="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className={`menu_toggle`}>
              <span className="hamburger">
                <span />
                <span />
                <span />
              </span>
              <span className="hamburger-cross">
                <span />
                <span />
              </span>
            </span>
          </button>

          <div
            className={classNames('collapse navbar-collapse', {
              show: !state.isMenuCollapsed,
            })}
            id="navbarSupportedContent"
            data-testid="navbar-show"
          >
            <Location>
              {({location}) => (
                <ul className={`navbar-nav menu w_menu pl_25`}>
                  {generateMenu(menuItems, location.pathname, user?.photo || 'https://hexomatic.com/avatar-hexomatic.svg')}
                </ul>
              )}
            </Location>
          </div>

          {!state.isDropDownOpened && (
            <>
              {user && user.email && email ? (
                <div
                  className={classNames('btn-group user-details ', {
                    show: isGravatarOpen,
                  })}
                >
                  <button
                    type="button"
                    onClick={toggle}
                    className="logged-btn is-dropdown name"
                    style={{
                      border: '0',
                      background: 'transparent',
                      color: '#323537',
                      marginRight: '10px',
                      cursor: 'pointer',
                    }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span
                      ref={refUserName}
                      className="user-name-2 text-truncate text-center"
                      data-tip={checkWidth.firstName ? user?.firstName : ''}
                      data-for="user-name"
                    >
                      {getUserName() || (user && user.firstName)}
                    </span>
                    <img alt={getUserName() || (user && user.firstName)} src={user?.photo || 'https://hexomatic.com/avatar-hexomatic.svg'} />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      if (window.location.host.includes('localhost')) {
                        window.location.href = 'http://localhost:3000/profile';
                      } else if (window.location.host.includes('37.186.119.181')) {
                        window.location.href = 'http://37.186.119.181:3279/profile';
                      } else {
                        window.location.href = 'https://dash.hexomatic.com/profile';
                      }
                    }}
                    className="logged-btn not-dropdown"
                    style={{
                      border: '0',
                      background: 'transparent',
                      color: '#323537',
                      marginRight: '10px',
                      cursor: 'pointer',
                    }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <ReactTooltip place="bottom" className="tooltip" id="user-name" data-id="user-name"/>
                    <span
                      ref={refName}
                      className="user-name text-truncate text-center"
                      data-tip={checkWidth.firstName1 ? user?.firstName : ''}
                      data-for="user-name"
                    >
                      {getUserName() || (user && user.firstName)}
                    </span>
                    <img alt={getUserName() || (user && user.firstName)} src={user?.photo || 'https://hexomatic.com/avatar-hexomatic.svg'} />
                  </button>

                  {isGravatarOpen && <button className="dropdown-back" type="button" onClick={toggle} />}
                  <div className="is-dropdown">
                    <div
                      className={classNames('dropdown-menu dropdown-menu-right', {
                        show: isGravatarOpen,
                      })}
                    >
                      <li>
                        <a
                          href={
                            window.location.host.includes('localhost')
                              ? 'http://localhost:3000'
                              : window.location.host.includes('37.186.119.181')
                              ? 'http://37.186.119.181:3279'
                              : 'https://dash.hexomatic.com'
                          }
                          className="dropdown-item m-0 p-0"
                          no-index
                        >
                          <FontAwesomeIcon icon={faChartLine} className="user-icon" />
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item m-0 p-0" onClick={logOutHandler}>
                          <FontAwesomeIcon icon={faSignOutAlt} className="user-icon" />
                          Sign out
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="not-dropdown">
                    <div style={{margin: '0 10px'}}>
                      <a
                        href={
                          window.location.host.includes('localhost')
                            ? 'http://localhost:3000'
                            : window.location.host.includes('37.186.119.181')
                            ? 'http://37.186.119.181:3279'
                            : 'https://dash.hexomatic.com'
                        }
                        className="dropdown-item p-0"
                        no-index
                      >
                        <FontAwesomeIcon icon={faChartLine} className="user-icon" />
                        Dashboard
                      </a>
                    </div>
                    <div style={{marginLeft: '10px'}}>
                      <a className="dropdown-item p-0" onClick={logOutHandler}>
                        <FontAwesomeIcon icon={faSignOutAlt} className="user-icon" />
                        Sign out
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {loading ? (
                    <div className="pulse-placeholder">
                      <div className="line-placeholder"></div>
                      <div className="line-placeholder-long"></div>
                      <div className="line-placeholder-last"></div>
                    </div>
                  ) : (
                    <>
                      <Link
                        className="software_banner_btn d-none d-md-block header_registration_btn"
                        to="/registration/"
                        state={{goBack: true, navigatePath: global?.window?.location?.pathname?.includes('pricing') || global?.window?.location?.pathname?.includes('promo') || global?.window?.location?.pathname?.includes('5-year-journey') ? global.window.location.pathname : ''}}
                      >
                        Get started
                      </Link>
                      <Link
                        //className="d-none d-md-block header_registration_btn login_btn"
                        //className="d-none d-md-block header_login_btn"
                        className="d-none d-md-block login_btn"
                        to="/login/"
                        state={{goBack: true, navigatePath: global?.window?.location?.pathname?.includes('pricing') || global?.window?.location?.pathname?.includes('promo') || global?.window?.location?.pathname?.includes('5-year-journey') ? global.window.location.pathname : ''}}
                      >
                        Login
                      </Link>
                      <div
                        className="software_banner_btn d-none d-md-block header_registration_btn outline ml-3"
                        // to="/#demo"
                        onClick={openCalendly}
                      >
                        Request a demo
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </nav>
      {/* {showInvestPopup ? (
        <Modal isOpen={showInvestPopup} modalToggle={() => {}} modalFooter={<></>} className="banner-modal">
          <span
            className="position-absolute close-icon"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShowInverstPopup(false);
              localStorage.setItem('become-investor-2', 'true');
            }}
          >
            {closeIcon}
          </span>
          <img
            src={isMobile ? popupMobile : popupDesktop}
            alt="Become an investor in Hexact"
            onClick={() => window.open('https://www.startengine.com/offering/hexact/?utm_source=hexomatic&utm_medium=pop-up', '_blank')}
          />
          <div className="popup-counter">
            <Counter date="Apr 01 2023 16:00:00 GMT" className='popup-countdown'/>
          </div>
        </Modal>
      ) : null} */}
    </header>
  );
};

export default Header;
